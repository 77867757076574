import React from 'react';
import InnerHTML from 'dangerously-set-html-content'
export default function Scrollind() {



const html ='<script> window.onscroll = function() {myFunction()};function myFunction() {  var winScroll = document.body.scrollTop || document.documentElement.scrollTop;  var height = document.documentElement.scrollHeight - document.documentElement.clientHeight;var scrolled = (winScroll / height) * 100;  document.getElementById("myBar").style.width = scrolled + "%";} </script>'
  return (

    <section id="prog" className="progressinprogress">
    <div className="header">
      <div className="progress-container">
      <InnerHTML html={html} />
      <div className="progress-bar" id="myBar"></div>
      </div>
    </div>
    </section>

  );
}
