import React from "react"
import { Helmet } from "react-helmet"
import { graphql } from "gatsby"
// import '../css/blog-post.css';
import Footer from '../components/Footer';
import Header from '../components/HeaderPost';
import Layout from '../components/Layout';
import SocialLinks from '../components/SocialLinks';
import Scrollind from '../components/Scrollind';
import Link from 'gatsby-link'

export default function Template({ data }) {

const { markdownRemark: post } = data
return (
<Layout>

  <Scrollind />



  <a href={post.frontmatter.to}>
    <button type="button" className="btn btn-danger btn-floating btn-lg" id="btn-back-to-top">
      {`back to ${post.frontmatter.from}`}
    </button>
  </a>

  <section id="head-post" className="about-section about-section3 text-left">
    <div className="container">
      <div className="row underline ">
        <div className="col-lg-10 mx-auto pad-me">
          <h2 className="mb-0">{post.frontmatter.title}</h2>
        </div>
      </div>
    </div>
  </section>

  <section id="return" className="returnback">
    <div className="container">
      <div className="row">
        <div className="col-lg-10 fix mx-auto">
          <h1 className="mb-0">{post.frontmatter.subtitle}</h1>
        </div>
      </div>
    </div>
  </section>

  <section id="markme" className="markme">
    <div className="blog-post-container pad-o-matic">

      <div className="container pad-o-wan">
        <div className="row justify-content-center no-gutters mb-5 mb-lg-0">
          <div className="col-lg-10 center pcard">
            <Helmet title={`mortified.space - ${post.frontmatter.title}`} />
            <div className="blog-post">
              <hr />
              <div className="blog-post-content " dangerouslySetInnerHTML={{ __html: post.html }} />
            </div>



            <div>
              <hr />
              <ul className="list-group list-group-horizontal">
                {post.frontmatter.tags.map(tag => {
                return (
                <li className="list-group-item">
                  <Link to={`/tags/${tag}`}>
                  {tag}
                  </Link>
                </li>
                )
                })}

                <li className="list-group-item list-all">
                  <Link to="/tags">
                  All tags
                  </Link>
                </li>

              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <SocialLinks />
  <Footer />

</Layout>
)
}

export const pageQuery = graphql`
query BlogPostByPath($path: String!) {
markdownRemark(frontmatter: { path: { eq: $path } }) {
html
frontmatter {
date(formatString: "MMMM DD, YYYY")
path
title
subtitle
tags
from
to
}
}
}
`
